import { ReactNode, createContext, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { MutateHammrUser } from 'interfaces/user';
import { defaultHammrHeaders } from 'utils/requestHelpers';
import { transformUser, transformGetHammrUsers } from 'utils/dataTransformers';
import { updateHammrUser, getHammrUser, createHammrUser } from 'services/user';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { AppError, AppNetworkError } from 'utils/AppError';
import { logError, validateNetworkResponse } from 'utils/errorHandling';
import { SENDBIRD_SESSION_KEY } from 'components/chat/hooks/useSendbirdSession';

const authContext = createContext({ user: {} });
const { Provider } = authContext;

// AuthProvider is a Context Provider that wraps our app and makes an auth object
// available to any child component that calls the useAuth() hook.
export function AuthProvider(props: { children: ReactNode }): JSX.Element {
  const auth = useAuthProvider();
  return <Provider value={auth}>{props.children}</Provider>;
}

// useAuth is a hook that enables any component to subscribe to auth state
export const useAuth: any = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
const useAuthProvider = () => {
  const [user, setUser] = useLocalStorage('user', null);
  const router = useRouter();

  const getUser = async (id: string) => {
    if (!id) return;
    try {
      const userData = await getHammrUser(id);
      const mappedUser = transformGetHammrUsers(userData);
      setUser(mappedUser);
    } catch (error) {
      logError(error);
      throw new AppError(error);
    }
  };

  // used in Edit page
  // still needs to be tested!!!
  const updateUser = async (id: string, data: object, skipRefetch = false) => {
    try {
      await updateHammrUser(id, data);
      if (!skipRefetch && `${user?.id}` === id) {
        await getUser(id);
      }
    } catch (error) {
      logError(error);
      throw new AppError(error);
    }
  };

  const addEmployeeToHammr = async (data) => {
    const employeeUser: Partial<MutateHammrUser> = {
      email: data.email,
      firstName: `${data.first_name}`,
      lastName: `${data.last_name}`,
      organizationId: data.companyId,
      role: data.role || 'WORKER',
      position: data.position,
      createdAt: new Date(),
      employeeId: data.employeeId,
      checkEmployeeId: data.checkEmployeeId,
      hourlyRate: data.hourlyRate,
      phoneNumber: `${data.phone}`,
      fromPayroll: data?.fromPayroll || false,
      payrollItemId: data.payrollItemId,
      overtimePayrollItemId: data.otPayrollItemId,
      defaultCostCodeId: data.defaultCostCode,
    };

    try {
      const res = await createHammrUser(employeeUser);

      if (res.err) {
        throw new Error(res.err.message);
      }
    } catch (error) {
      logError(error);
      throw new AppError(error);
    }
  };

  const addContractorToHammr = async (data) => {
    const contractorUser: Partial<MutateHammrUser> = {
      email: data.email,
      firstName: `${data.first_name}`,
      lastName: `${data.last_name}`,
      organizationId: data.companyId,
      role: 'WORKER',
      position: data.position,
      createdAt: new Date(),
      checkContractorId: data.checkContractorId,
      hourlyRate: data.hourlyRate,
      phoneNumber: `+1${data.phone}`,
      fromPayroll: true,
      workerClassification: 'CONTRACTOR',
    };

    try {
      await createHammrUser(contractorUser);
    } catch (error) {
      logError(error);
      throw new AppError(error);
    }
  };

  const getOtp = async ({ phone }) => {
    try {
      const res = await fetch(
        `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v1/login`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            ...defaultHammrHeaders,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ phone }),
        }
      );

      if (!res.ok) {
        // 429 is rate limiting
        if (res.status === 429) {
          throw new Error('Too many requests. Please try again later.');
        }

        // get body for error messages, as 429 doesn't have body
        const body = await res.json();

        throw new Error(body.error || 'Failed to login.');
      }

      const data = await res.json();
      // Check for failure result from the backend
      if (data.result === 'failure') {
        throw new Error(data.error || 'Failed to login.');
      }

      return data;
    } catch (error) {
      logError(error);
      throw new AppNetworkError(error);
    }
  };

  const signInMobile = async ({ code }) => {
    try {
      const res = await fetch(
        `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v1/verify-otp`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            ...defaultHammrHeaders,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ code }),
        }
      );

      // if status isn't 200 - still throw
      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      const jsonResponse = await res.json();

      // wrong otp is a success response, but result is failture --> so throw
      if (jsonResponse.result === 'failure' && jsonResponse.error) {
        throw new Error(jsonResponse.error);
      }

      const { ...currentUser } = jsonResponse.data;
      // transform the response data into format that check-express looks for
      const mappedUser = transformUser(currentUser);
      setUser(mappedUser);
      return mappedUser;
    } catch (error) {
      logError(error);
      throw new AppNetworkError(error);
    }
  };

  const signOut = async () => {
    try {
      // optimistic update
      setUser(false);
      localStorage.removeItem(SENDBIRD_SESSION_KEY);
      await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v1/logout`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          ...defaultHammrHeaders,
        },
      });
      return;
    } catch (error) {
      logError(error);
      throw new AppNetworkError(error);
    }
  };

  useEffect(() => {
    // fetch hammr user data to be used in check express
    if (user?.id) {
      getHammrUser(user?.id)
        .then((data) => {
          if (data.err) {
            // throwing makes this hit the catch block and if unauthorized, redirects to /login
            throw new Error(data.err.message);
          }

          const mappedUser = transformGetHammrUsers(data);
          setUser(mappedUser);
        })
        .catch((err) => {
          logError(err);
          if (err.message === 'Unauthorized') {
            setUser(false);
            router.push('/login');
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  return {
    user,
    addEmployeeToHammr,
    addContractorToHammr,
    signOut,
    getOtp,
    signInMobile,
    getUser,
    setUser,
    updateUser,
  };
};
